@use 'src/styles/utils/variables';
@use 'src/styles/utils/fonts';

.changeLanguage {
  display: inline-block;
}

.language__select,
.language__dropdown {
  img {
    display: inline-block;
    width: 20px;
    padding-right: 5px;
  }
}

.language__select.language__select {
  font-weight: fonts.$font-semi-bold;
  width: 100px;
  :global {
    .ant-select-selector.ant-select-selector {
      background-color: transparent;
      border: none;
      box-shadow: none;

      span {
        color: variables.$cc-white;
      }
    }
  }
}
.language__dropdown {
  text-align: center;
  :global {
    .ant-select-item-option-selected {
      background-color: variables.$cc-purple-dark;
      color: variables.$cc-white;
    }
  }
}
