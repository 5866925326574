@import '~antd/dist/antd.css';
@import '../utils/variables';
@import '../utils/fonts';

// Buttons
.ant-btn-background-ghost {
  &.ant-btn-link {
    &:hover {
      color: inherit;
      transform: scale(1.5);
    }
  }
}

// Grid
.ant-layout-content {
  padding: 0 $gap-big;
}

// Forms
.ant-select {
  $input-height: 50px;
  .ant-select-selection {
    &--single {
      height: $input-height;
      font-size: 1.2rem;
    }
    &__rendered {
      line-height: $input-height;
    }
  }
}

.ant-input {
  height: 50px;
}
