@use 'src/styles/utils/variables';

.cc-theme-purple {
  .main-layout {
    background-color: variables.$cc-white;

    &__header {
      height: auto;
      background-color: variables.$cc-purple;
      @media screen and (max-width: 634px) {
        display: flex;
        align-items: center;
        padding: 0 20px;
        overflow-y: hidden;
      }
    }

    &__content {
      background-color: variables.$cc-white;
      position: relative;

      .ant-spin {
        background-color: variables.$cc-white;
        .ant-spin-dot {
          width: 3rem;
          height: 3rem;

          .ant-spin-dot-item {
            background-color: variables.$cc-purple;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
