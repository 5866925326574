@use 'src/styles/utils/variables';
@use 'src/styles/utils/fonts';
@use 'src/styles/utils/mixins';

.converter {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 40px auto 40px;
  margin-top: variables.$gap-big;
  margin-bottom: variables.$gap-big;

  &__info {
    @include mixins.background-image-with-linear-gradient(
                    variables.$cc-purple-dark,
                    variables.$cc-purple,
                    '../../assets/img/bg-calc-info.jpg',
                    0.07,
                    0.1
    );
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column: 1 / 7;
    grid-row: 1 / 4;
    padding: variables.$gap-big 0;
    background-color: variables.$cc-purple;
    background-position: center;
    background-size: cover;
    color: variables.$cc-white;

    &__content {
      grid-column: 2 / 5;
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;

      &::before {
        content: '\f10d';
        display: block;
        margin-bottom: 20px;
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: fonts.$fa-weight-bold;
        font-size: 4rem;
      }

      strong {
        font-style: italic;
      }

      p:last-of-type {
        margin-bottom: 0;
        margin-top: auto;
      }
    }
  }

  &__calc__shadow {
    grid-column: 7 / 13;
    grid-row: 2;
    border-radius: 5px;
    box-shadow: 0 0 20px variables.$cc-purple-dark;
  }

  &__calc {
    position: relative;
    z-index: 2;
    grid-column: 6 / 13;
    grid-row: 2;
    min-height: 470px;
    padding: 40px;
    background-color: variables.$cc-white;
    text-align: center;
    border-radius: 5px;

    .buttons {
      display: flex;
      justify-content: space-between;

      .chart-button {
        display: inline-block;
      }
    }

    &__header {
      margin-bottom: 30px;
    }

    .ant-spin {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3rem;
      width: 3rem;
      margin: auto;
    }

    &__group {
      margin: 10px 0;
      text-align: left;

      .ant-select,
      .ant-input {
        display: block;
        margin-bottom: 10px;
      }

      .ant-input {
        font-size: 1.2rem;
      }
    }

    .btn {
      height: 40px;
      margin: 10px 0;

      &-swap {
        width: 70px;

        &-icon {
          font-size: 1.5rem;
        }
      }

      &-chart {
        display: inline-flex;
        justify-content: center;

        &-icon {
          font-size: 1.5rem;
          margin-right: 10px;
        }
      }
    }

    &__rate p {
      font-size: 0.9rem;
      font-weight: fonts.$font-semi-bold;
      font-style: italic;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1201px) {
  .converter {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    &__info {
      &__content {
        &::before {
          display: none;
        }
      }
    }

    &__calc {
      width: 500px;
    }
  }
}

@media screen and (max-width: 768px) {
  .converter__info {
    display: flex;
    padding: 20px;
  }
  .main-layout__content {
    padding: 0 20px;
  }
}

// TODO: Move main-layout* styles to a proper file
@media screen and (max-width: 576px) {
  .main-layout__content {
    padding: 0 10px;

    .converter__info__content {
      font-size: 1rem;
    }
  }

  .converter {
    display: flex;

    &__calc {
      width: 300px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
