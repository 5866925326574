@use 'src/styles/utils/variables';
@use 'src/styles/utils/fonts';

.navLink {
  display: inline-block;
  color: variables.$cc-white;
  font-weight: fonts.$font-semi-bold;
  transition: all 0.3s ease-in-out;

  :global {
    .anticon {
      display: inline-block;
      margin-right: 10px;
      color: rgba(variables.$cc-white, 0.5);
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    transition: color 0.3s ease-in-out;

    &::before {
      content: attr(data-hover);
      position: absolute;
      color: variables.$cc-white;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
  }

  a {
    display: block;
    padding: 0 20px;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &.active {
      background-color: variables.$cc-purple-dark;
      box-shadow: 0 2px 5px variables.$cc-purple-dark;
    }
  }

  &:hover a:not(.active) {
    .navLink__label {
      color: transparent;

      &::before {
        opacity: 0.5;
        transform: scale(0.95);
      }
    }
  }

  @media screen and (max-width: 634px) {
    a {
      padding: 0 10px;
    }
  }
}
