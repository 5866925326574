.line-chart {
  position: relative;
  height: 430px;
  max-height: 70vh;
}

@media screen and (min-width: 415px) {
  .line-chart {
    height: 600px;
  }
}
