@use 'src/styles/utils/variables';

.form {
  padding: 10px;

  textarea {
    min-height: 150px;
    max-height: 200px;
  }

  .submitButton {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;
  }
}
