// Colors
$cc-white: #fff;
$cc-black: #000;

$cc-purple: #9300f7;
$cc-purple-dark: #591de7;

$cc-maroon: #913A7A;
$cc-blue: #1449F5;

$cc-gray-light: #dadada;

// Grid
$gap-big: 50px;
