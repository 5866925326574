@use 'src/styles/utils/variables';
@use 'src/styles/utils/fonts';

.main-header {
  display: flex;
  justify-content: space-between;

  &__logo {
    margin: 0;
    color: variables.$cc-white;
    font-size: 2rem;
    font-weight: fonts.$font-semi-bold;
    line-height: 40px;
    text-align: center;
    text-shadow: 1px 1px 1px variables.$cc-purple-dark;

    span {
      display: block;
      font-size: 0.9rem;
      line-height: 0.5;
    }
  }

  &__hamburger {
    display: none;
  }

  &__menu {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 0 0 auto;
  }
}

@media screen and (max-width: 634px) {
  .main-header {
    flex-wrap: wrap;
    &__logo {
      font-size: 1.5rem;

      span {
        font-size: 0.6rem;
      }
    }

    &__menu {
      order: 3;
      width: 100%;
      max-height: 0;
      text-align: center;
      line-height: 40px;
      transition: max-height 0.3s ease-in-out;
      &::before,
      &::after {
        content: '';
        display: block;
        background-color: variables.$cc-white;
        margin: 0 -20px;
      }
      &::before {
        height: 1px;
      }
      &::after {
        height: 10px;
      }
    }

    &__hamburger {
      display: inline-block;
      align-self: center;
      border: 1px solid variables.$cc-white;
      border-radius: 5px;
      cursor: pointer;
      .line {
        display: block;
        width: 20px;
        height: 2px;
        background-color: variables.$cc-white;
        margin: 5px;
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
      }
      &.active {
        background-color: variables.$cc-purple-dark;
        .line {
          &:nth-child(1) {
            transform: rotate(40deg) translateY(6px) translateX(5px);
          }
          &:nth-child(2) {
            transform: scale(0);
          }
          &:nth-child(3) {
            transform: rotate(-40deg) translateY(-5px) translateX(4px);
          }
        }
        + .main-header__menu {
          max-height: 5em;
          &::after {
            background-color: variables.$cc-white;
          }
        }
      }
    }
  }
}
