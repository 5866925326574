@mixin rotate($deg) {
  transform: rotate(#{$deg}deg);
}

@mixin linear-gradient($color1, $color2, $deg: to right) {
  background-color: $color1;
  background-image: linear-gradient($deg, $color1, $color2, $color1);

  background-size: 200% 200%;
  transition: background 0.3s ease-in-out;

  &:hover {
    background-position: 100% 100%;
  }
}

@mixin background-image-with-linear-gradient(
  $colorFrom,
  $colorTo,
  $image,
  $colorFromOpacity: 0.1,
  $colorToOpacity: 0.1
) {
  background-image: linear-gradient(
      45deg,
      transparentize($colorFrom, $colorFromOpacity),
      transparentize($colorTo, $colorToOpacity)
    ),
    url($image);
}
