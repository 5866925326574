@use 'src/styles/utils/variables';
@use 'src/styles/utils/mixins';

.contact {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .contact__details {
    @include mixins.linear-gradient(variables.$cc-purple, variables.$cc-purple-dark);
    width: 300px;
    height: 600px;
    padding: 10px;
    color: variables.$cc-white;

    h2 {
      font-size: 1.17rem;
      margin-left: 20px;
    }

    .contact__details__icons {
      margin-left: 10px;
    }
    .contact__details__icon {
      display: block;
      margin-top: 50px;
      margin-left: 30px;
      font-size: 3rem;

      a {
        display: inline-block;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .contact__form {
    width: 600px;
    padding: 10px;
    margin: auto -120px;
    background-color: variables.$cc-white;
    box-shadow: 0 0 20px variables.$cc-purple-dark;
    border-radius: 5px;
    text-align: center;
  }
}

@media screen and (max-width: 883px) {
  .contact {
    display: block;
    margin: auto auto 20px;

    .contact__details {
      width: 600px;
      height: 150px;
      text-align: center;

      .contact__details__icons {
        display: flex;
        justify-content: space-around;
      }
      .contact__details__icon {
        margin-top: 0;
        margin-left: 0;
      }
    }

    .contact__form {
      width: 550px;
      margin: -20px auto auto;
    }
  }
}

@media screen and (max-width: 634px) {
  .contact {
    .contact__details {
      width: 450px;
    }

    .contact__form {
      width: 400px;
    }
  }
}

@media screen and (max-width: 460px) {
  .contact {
    .contact__details {
      width: 320px;

      .contact__details__icons {
        .facebook,
        .github,
        .site {
          font-size: 2rem;
        }
      }
    }

    .contact__form {
      width: 300px;
      margin-top: -55px;
    }
  }
}
