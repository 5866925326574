@use '../utils/fonts';

h1,
h2,
h3,
h4,
h5 {
  color: inherit;
  font-weight: fonts.$font-bold;
}

h2 {
  font-size: 2rem;
}
